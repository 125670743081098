const pt_BR = [
    {
      name: 'Afeganistão',
      dial_code: '+93',
      emoji: '🇦🇫',
      id: 'AF',
    },
    {
      name: 'Ilhas Aland',
      dial_code: '+358',
      emoji: '🇦🇽',
      id: 'AX',
    },
    {
      name: 'Albânia',
      dial_code: '+355',
      emoji: '🇦🇱',
      id: 'AL',
    },
    {
      name: 'Argélia',
      dial_code: '+213',
      emoji: '🇩🇿',
      id: 'DZ',
    },
    {
      name: 'Samoa Americana',
      dial_code: '+1684',
      emoji: '🇦🇸',
      id: 'AS',
    },
    {
      name: 'Andorra',
      dial_code: '+376',
      emoji: '🇦🇩',
      id: 'AD',
    },
    {
      name: 'Angola',
      dial_code: '+244',
      emoji: '🇦🇴',
      id: 'AO',
    },
    {
      name: 'Anguilla',
      dial_code: '+1264',
      emoji: '🇦🇮',
      id: 'AI',
    },
    {
      name: 'Antártica',
      dial_code: '+672',
      emoji: '🇦🇶',
      id: 'AQ',
    },
    {
      name: 'Antígua e Barbuda',
      dial_code: '+1268',
      emoji: '🇦🇬',
      id: 'AG',
    },
    {
      name: 'Argentina',
      dial_code: '+54',
      emoji: '🇦🇷',
      id: 'AR',
    },
    {
      name: 'Armênia',
      dial_code: '+374',
      emoji: '🇦🇲',
      id: 'AM',
    },
    {
      name: 'Aruba',
      dial_code: '+297',
      emoji: '🇦🇼',
      id: 'AW',
    },
    {
      name: 'Austrália',
      dial_code: '+61',
      emoji: '🇦🇺',
      id: 'AU',
    },
    {
      name: 'Áustria',
      dial_code: '+43',
      emoji: '🇦🇹',
      id: 'AT',
    },
    {
      name: 'Azerbaijão',
      dial_code: '+994',
      emoji: '🇦🇿',
      id: 'AZ',
    },
    {
      name: 'Bahamas',
      dial_code: '+1242',
      emoji: '🇧🇸',
      id: 'BS',
    },
    {
      name: 'Bahrein',
      dial_code: '+973',
      emoji: '🇧🇭',
      id: 'BH',
    },
    {
      name: 'Bangladesh',
      dial_code: '+880',
      emoji: '🇧🇩',
      id: 'BD',
    },
    {
      name: 'Barbados',
      dial_code: '+1246',
      emoji: '🇧🇧',
      id: 'BB',
    },
    {
      name: 'Bielorrússia',
      dial_code: '+375',
      emoji: '🇧🇾',
      id: 'BY',
    },
    {
      name: 'Bélgica',
      dial_code: '+32',
      emoji: '🇧🇪',
      id: 'BE',
    },
    {
      name: 'Belize',
      dial_code: '+501',
      emoji: '🇧🇿',
      id: 'BZ',
    },
    {
      name: 'Benim',
      dial_code: '+229',
      emoji: '🇧🇯',
      id: 'BJ',
    },
    {
      name: 'Bermudas',
      dial_code: '+1441',
      emoji: '🇧🇲',
      id: 'BM',
    },
    {
      name: 'Butão',
      dial_code: '+975',
      emoji: '🇧🇹',
      id: 'BT',
    },
    {
      name: 'Bolívia',
      dial_code: '+591',
      emoji: '🇧🇴',
      id: 'BO',
    },
    {
      name: 'Bósnia e Herzegovina',
      dial_code: '+387',
      emoji: '🇧🇦',
      id: 'BA',
    },
    {
      name: 'Botswana',
      dial_code: '+267',
      emoji: '🇧🇼',
      id: 'BW',
    },
    {
      name: 'Brasil',
      dial_code: '+55',
      emoji: '🇧🇷',
      id: 'BR',
    },
    {
      name: 'Território Britânico do Oceano Índico',
      dial_code: '+246',
      emoji: '🇮🇴',
      id: 'IO',
    },
    {
      name: 'Brunei',
      dial_code: '+673',
      emoji: '🇧🇳',
      id: 'BN',
    },
    {
      name: 'Bulgária',
      dial_code: '+359',
      emoji: '🇧🇬',
      id: 'BG',
    },
    {
      name: 'Burkina Faso',
      dial_code: '+226',
      emoji: '🇧🇫',
      id: 'BF',
    },
    {
      name: 'Burundi',
      dial_code: '+257',
      emoji: '🇧🇮',
      id: 'BI',
    },
    {
      name: 'Camboja',
      dial_code: '+855',
      emoji: '🇰🇭',
      id: 'KH',
    },
    {
      name: 'Camarões',
      dial_code: '+237',
      emoji: '🇨🇲',
      id: 'CM',
    },
    {
      name: 'Canadá',
      dial_code: '+1',
      emoji: '🇨🇦',
      id: 'CA',
    },
    {
      name: 'Cabo Verde',
      dial_code: '+238',
      emoji: '🇨🇻',
      id: 'CV',
    },
    {
      name: 'Ilhas Cayman',
      dial_code: '+345',
      emoji: '🇰🇾',
      id: 'KY',
    },
    {
      name: 'República Centro-Africana',
      dial_code: '+236',
      emoji: '🇨🇫',
      id: 'CF',
    },
    {
      name: 'Chade',
      dial_code: '+235',
      emoji: '🇹🇩',
      id: 'TD',
    },
    {
      name: 'Chile',
      dial_code: '+56',
      emoji: '🇨🇱',
      id: 'CL',
    },
    {
      name: 'China',
      dial_code: '+86',
      emoji: '🇨🇳',
      id: 'CN',
    },
    {
      name: 'Ilha Christmas',
      dial_code: '+61',
      emoji: '🇨🇽',
      id: 'CX',
    },
    {
      name: 'Ilhas Cocos (Keeling)',
      dial_code: '+61',
      emoji: '🇨🇨',
      id: 'CC',
    },
    {
      name: 'Colômbia',
      dial_code: '+57',
      emoji: '🇨🇴',
      id: 'CO',
    },
    {
      name: 'Comores',
      dial_code: '+269',
      emoji: '🇰🇲',
      id: 'KM',
    },
    {
      name: 'Congo',
      dial_code: '+242',
      emoji: '🇨🇬',
      id: 'CG',
    },
    {
      name: 'Congo, República Democrática do',
      dial_code: '+243',
      emoji: '🇨🇩',
      id: 'CD',
    },
    {
      name: 'Ilhas Cook',
      dial_code: '+682',
      emoji: '🇨🇰',
      id: 'CK',
    },
    {
      name: 'Costa Rica',
      dial_code: '+506',
      emoji: '🇨🇷',
      id: 'CR',
    },
    {
      name: 'Costa do Marfim',
      dial_code: '+225',
      emoji: '🇨🇮',
      id: 'CI',
    },
    {
      name: 'Croácia',
      dial_code: '+385',
      emoji: '🇭🇷',
      id: 'HR',
    },
    {
      name: 'Cuba',
      dial_code: '+53',
      emoji: '🇨🇺',
      id: 'CU',
    },
    {
      name: 'Chipre',
      dial_code: '+357',
      emoji: '🇨🇾',
      id: 'CY',
    },
    {
      name: 'República Tcheca',
      dial_code: '+420',
      emoji: '🇨🇿',
      id: 'CZ',
    },
    {
      name: 'Dinamarca',
      dial_code: '+45',
      emoji: '🇩🇰',
      id: 'DK',
    },
    {
      name: 'Djibuti',
      dial_code: '+253',
      emoji: '🇩🇯',
      id: 'DJ',
    },
    {
      name: 'Dominica',
      dial_code: '+1767',
      emoji: '🇩🇲',
      id: 'DM',
    },
    {
      name: 'República Dominicana',
      dial_code: '+1849',
      emoji: '🇩🇴',
      id: 'DO',
    },
    {
      name: 'Equador',
      dial_code: '+593',
      emoji: '🇪🇨',
      id: 'EC',
    },
    {
      name: 'Egito',
      dial_code: '+20',
      emoji: '🇪🇬',
      id: 'EG',
    },
    {
      name: 'El Salvador',
      dial_code: '+503',
      emoji: '🇸🇻',
      id: 'SV',
    },
    {
      name: 'Guiné Equatorial',
      dial_code: '+240',
      emoji: '🇬🇶',
      id: 'GQ',
    },
    {
      name: 'Eritreia',
      dial_code: '+291',
      emoji: '🇪🇷',
      id: 'ER',
    },
    {
      name: 'Estônia',
      dial_code: '+372',
      emoji: '🇪🇪',
      id: 'EE',
    },
    {
      name: 'Eswatini',
      dial_code: '+268',
      emoji: '🇸🇿',
      id: 'SZ',
    },
    {
      name: 'Etiópia',
      dial_code: '+251',
      emoji: '🇪🇹',
      id: 'ET',
    },
    {
      name: 'Ilhas Malvinas (Falkland)',
      dial_code: '+500',
      emoji: '🇫🇰',
      id: 'FK',
    },
    {
      name: 'Ilhas Faroé',
      dial_code: '+298',
      emoji: '🇫🇴',
      id: 'FO',
    },
    {
      name: 'Fiji',
      dial_code: '+679',
      emoji: '🇫🇯',
      id: 'FJ',
    },
    {
      name: 'Finlândia',
      dial_code: '+358',
      emoji: '🇫🇮',
      id: 'FI',
    },
    {
      name: 'França',
      dial_code: '+33',
      emoji: '🇫🇷',
      id: 'FR',
    },
    {
      name: 'Guiana Francesa',
      dial_code: '+594',
      emoji: '🇬🇫',
      id: 'GF',
    },
    {
      name: 'Polinésia Francesa',
      dial_code: '+689',
      emoji: '🇵🇫',
      id: 'PF',
    },
    {
      name: 'Gabão',
      dial_code: '+241',
      emoji: '🇬🇦',
      id: 'GA',
    },
    {
      name: 'Gâmbia',
      dial_code: '+220',
      emoji: '🇬🇲',
      id: 'GM',
    },
    {
      name: 'Geórgia',
      dial_code: '+995',
      emoji: '🇬🇪',
      id: 'GE',
    },
    {
      name: 'Alemanha',
      dial_code: '+49',
      emoji: '🇩🇪',
      id: 'DE',
    },
    {
      name: 'Gana',
      dial_code: '+233',
      emoji: '🇬🇭',
      id: 'GH',
    },
    {
      name: 'Gibraltar',
      dial_code: '+350',
      emoji: '🇬🇮',
      id: 'GI',
    },
    {
      name: 'Grécia',
      dial_code: '+30',
      emoji: '🇬🇷',
      id: 'GR',
    },
    {
      name: 'Groenlândia',
      dial_code: '+299',
      emoji: '🇬🇱',
      id: 'GL',
    },
    {
      name: 'Granada',
      dial_code: '+1473',
      emoji: '🇬🇩',
      id: 'GD',
    },
    {
      name: 'Guadalupe',
      dial_code: '+590',
      emoji: '🇬🇵',
      id: 'GP',
    },
    {
      name: 'Guão',
      dial_code: '+1671',
      emoji: '🇬🇺',
      id: 'GU',
    },
    {
      name: 'Guatemala',
      dial_code: '+502',
      emoji: '🇬🇹',
      id: 'GT',
    },
    {
      name: 'Guernsey',
      dial_code: '+44',
      emoji: '🇬🇬',
      id: 'GG',
    },
    {
      name: 'Guiné',
      dial_code: '+224',
      emoji: '🇬🇳',
      id: 'GN',
    },
    {
      name: 'Guiné-Bissau',
      dial_code: '+245',
      emoji: '🇬🇼',
      id: 'GW',
    },
    {
      name: 'Guiana',
      dial_code: '+592',
      emoji: '🇬🇾',
      id: 'GY',
    },
    {
      name: 'Haiti',
      dial_code: '+509',
      emoji: '🇭🇹',
      id: 'HT',
    },
    {
      name: 'Honduras',
      dial_code: '+504',
      emoji: '🇭🇳',
      id: 'HN',
    },
    {
      name: 'Hong Kong',
      dial_code: '+852',
      emoji: '🇭🇰',
      id: 'HK',
    },
    {
      name: 'Hungria',
      dial_code: '+36',
      emoji: '🇭🇺',
      id: 'HU',
    },
    {
      name: 'Islândia',
      dial_code: '+354',
      emoji: '🇮🇸',
      id: 'IS',
    },
    {
      name: 'Índia',
      dial_code: '+91',
      emoji: '🇮🇳',
      id: 'IN',
    },
    {
      name: 'Indonésia',
      dial_code: '+62',
      emoji: '🇮🇩',
      id: 'ID',
    },
    {
      name: 'Irã',
      dial_code: '+98',
      emoji: '🇮🇷',
      id: 'IR',
    },
    {
      name: 'Iraque',
      dial_code: '+964',
      emoji: '🇮🇶',
      id: 'IQ',
    },
    {
      name: 'Irlanda',
      dial_code: '+353',
      emoji: '🇮🇪',
      id: 'IE',
    },
    {
      name: 'Ilha de Man',
      dial_code: '+44',
      emoji: '🇮🇲',
      id: 'IM',
    },
    {
      name: 'Israel',
      dial_code: '+972',
      emoji: '🇮🇱',
      id: 'IL',
    },
    {
      name: 'Itália',
      dial_code: '+39',
      emoji: '🇮🇹',
      id: 'IT',
    },
    {
      name: 'Jamaica',
      dial_code: '+1876',
      emoji: '🇯🇲',
      id: 'JM',
    },
    {
      name: 'Japão',
      dial_code: '+81',
      emoji: '🇯🇵',
      id: 'JP',
    },
    {
      name: 'Jersey',
      dial_code: '+44',
      emoji: '🇯🇪',
      id: 'JE',
    },
    {
      name: 'Jordânia',
      dial_code: '+962',
      emoji: '🇯🇴',
      id: 'JO',
    },
    {
      name: 'Cazaquistão',
      dial_code: '+7',
      emoji: '🇰🇿',
      id: 'KZ',
    },
    {
      name: 'Quênia',
      dial_code: '+254',
      emoji: '🇰🇪',
      id: 'KE',
    },
    {
      name: 'Kiribati',
      dial_code: '+686',
      emoji: '🇰🇮',
      id: 'KI',
    },
    {
      name: 'Coreia do Norte',
      dial_code: '+850',
      emoji: '🇰🇵',
      id: 'KP',
    },
    {
      name: 'Coreia do Sul',
      dial_code: '+82',
      emoji: '🇰🇷',
      id: 'KR',
    },
    {
      name: 'Kuwait',
      dial_code: '+965',
      emoji: '🇰🇼',
      id: 'KW',
    },
    {
      name: 'Quirguistão',
      dial_code: '+996',
      emoji: '🇰🇬',
      id: 'KG',
    },
    {
      name: 'Laos',
      dial_code: '+856',
      emoji: '🇱🇦',
      id: 'LA',
    },
    {
      name: 'Letônia',
      dial_code: '+371',
      emoji: '🇱🇻',
      id: 'LV',
    },
    {
      name: 'Líbano',
      dial_code: '+961',
      emoji: '🇱🇧',
      id: 'LB',
    },
    {
      name: 'Lesoto',
      dial_code: '+266',
      emoji: '🇱🇸',
      id: 'LS',
    },
    {
      name: 'Libéria',
      dial_code: '+231',
      emoji: '🇱🇷',
      id: 'LR',
    },
    {
      name: 'Líbia',
      dial_code: '+218',
      emoji: '🇱🇾',
      id: 'LY',
    },
    {
      name: 'Liechtenstein',
      dial_code: '+423',
      emoji: '🇱🇮',
      id: 'LI',
    },
    {
      name: 'Lituânia',
      dial_code: '+370',
      emoji: '🇱🇹',
      id: 'LT',
    },
    {
      name: 'Luxemburgo',
      dial_code: '+352',
      emoji: '🇱🇺',
      id: 'LU',
    },
    {
      name: 'Macau',
      dial_code: '+853',
      emoji: '🇲🇴',
      id: 'MO',
    },
    {
      name: 'Macedônia do Norte',
      dial_code: '+389',
      emoji: '🇲🇰',
      id: 'MK',
    },
    {
      name: 'Madagascar',
      dial_code: '+261',
      emoji: '🇲🇬',
      id: 'MG',
    },
    {
      name: 'Malawi',
      dial_code: '+265',
      emoji: '🇲🇼',
      id: 'MW',
    },
    {
      name: 'Malásia',
      dial_code: '+60',
      emoji: '🇲🇾',
      id: 'MY',
    },
    {
      name: 'Maldivas',
      dial_code: '+960',
      emoji: '🇲🇻',
      id: 'MV',
    },
    {
      name: 'Mali',
      dial_code: '+223',
      emoji: '🇲🇱',
      id: 'ML',
    },
    {
      name: 'Malta',
      dial_code: '+356',
      emoji: '🇲🇹',
      id: 'MT',
    },
    {
      name: 'Ilhas Marshall',
      dial_code: '+692',
      emoji: '🇲🇭',
      id: 'MH',
    },
    {
      name: 'Martinica',
      dial_code: '+596',
      emoji: '🇲🇶',
      id: 'MQ',
    },
    {
      name: 'Mauritânia',
      dial_code: '+222',
      emoji: '🇲🇷',
      id: 'MR',
    },
    {
      name: 'Maurício',
      dial_code: '+230',
      emoji: '🇲🇺',
      id: 'MU',
    },
    {
      name: 'Mayotte',
      dial_code: '+262',
      emoji: '🇾🇹',
      id: 'YT',
    },
    {
      name: 'México',
      dial_code: '+52',
      emoji: '🇲🇽',
      id: 'MX',
    },
    {
      name: 'Micronésia',
      dial_code: '+691',
      emoji: '🇫🇲',
      id: 'FM',
    },
    {
      name: 'Moldávia',
      dial_code: '+373',
      emoji: '🇲🇩',
      id: 'MD',
    },
    {
      name: 'Mônaco',
      dial_code: '+377',
      emoji: '🇲🇨',
      id: 'MC',
    },
    {
      name: 'Mongólia',
      dial_code: '+976',
      emoji: '🇲🇳',
      id: 'MN',
    },
    {
      name: 'Montenegro',
      dial_code: '+382',
      emoji: '🇲🇪',
      id: 'ME',
    },
    {
      name: 'Montserrat',
      dial_code: '+1664',
      emoji: '🇲🇸',
      id: 'MS',
    },
    {
      name: 'Marrocos',
      dial_code: '+212',
      emoji: '🇲🇦',
      id: 'MA',
    },
    {
      name: 'Moçambique',
      dial_code: '+258',
      emoji: '🇲🇿',
      id: 'MZ',
    },
    {
      name: 'Mianmar',
      dial_code: '+95',
      emoji: '🇲🇲',
      id: 'MM',
    },
    {
      name: 'Namíbia',
      dial_code: '+264',
      emoji: '🇳🇦',
      id: 'NA',
    },
    {
      name: 'Nauru',
      dial_code: '+674',
      emoji: '🇳🇷',
      id: 'NR',
    },
    {
      name: 'Nepal',
      dial_code: '+977',
      emoji: '🇳🇵',
      id: 'NP',
    },
    {
      name: 'Holanda',
      dial_code: '+31',
      emoji: '🇳🇱',
      id: 'NL',
    },
    {
      name: 'Antilhas Neerlandesas',
      dial_code: '+599',
      emoji: '🇧🇶',
      id: 'AN',
    },
    {
      name: 'Nova Caledônia',
      dial_code: '+687',
      emoji: '🇳🇨',
      id: 'NC',
    },
    {
      name: 'Nova Zelândia',
      dial_code: '+64',
      emoji: '🇳🇿',
      id: 'NZ',
    },
    {
      name: 'Nicarágua',
      dial_code: '+505',
      emoji: '🇳🇮',
      id: 'NI',
    },
    {
      name: 'Níger',
      dial_code: '+227',
      emoji: '🇳🇪',
      id: 'NE',
    },
    {
      name: 'Nigéria',
      dial_code: '+234',
      emoji: '🇳🇬',
      id: 'NG',
    },
    {
      name: 'Niue',
      dial_code: '+683',
      emoji: '🇳🇺',
      id: 'NU',
    },
    {
      name: 'Ilha Norfolk',
      dial_code: '+672',
      emoji: '🇳🇫',
      id: 'NF',
    },
    {
      name: 'Ilhas Marianas do Norte',
      dial_code: '+1670',
      emoji: '🇲🇵',
      id: 'MP',
    },
    {
      name: 'Noruega',
      dial_code: '+47',
      emoji: '🇳🇴',
      id: 'NO',
    },
    {
      name: 'Omã',
      dial_code: '+968',
      emoji: '🇴🇲',
      id: 'OM',
    },
    {
      name: 'Paquistão',
      dial_code: '+92',
      emoji: '🇵🇰',
      id: 'PK',
    },
    {
      name: 'Palau',
      dial_code: '+680',
      emoji: '🇵🇼',
      id: 'PW',
    },
    {
      name: 'Palestina, Estado da',
      dial_code: '+970',
      emoji: '🇵🇸',
      id: 'PS',
    },
    {
      name: 'Panamá',
      dial_code: '+507',
      emoji: '🇵🇦',
      id: 'PA',
    },
    {
      name: 'Papua Nova Guiné',
      dial_code: '+675',
      emoji: '🇵🇬',
      id: 'PG',
    },
    {
      name: 'Paraguai',
      dial_code: '+595',
      emoji: '🇵🇾',
      id: 'PY',
    },
    {
      name: 'Peru',
      dial_code: '+51',
      emoji: '🇵🇪',
      id: 'PE',
    },
    {
      name: 'Filipinas',
      dial_code: '+63',
      emoji: '🇵🇭',
      id: 'PH',
    },
    {
      name: 'Pitcairn',
      dial_code: '+872',
      emoji: '🇵🇳',
      id: 'PN',
    },
    {
      name: 'Polônia',
      dial_code: '+48',
      emoji: '🇵🇱',
      id: 'PL',
    },
    {
      name: 'Portugal',
      dial_code: '+351',
      emoji: '🇵🇹',
      id: 'PT',
    },
    {
      name: 'Porto Rico',
      dial_code: '+1939',
      emoji: '🇵🇷',
      id: 'PR',
    },
    {
      name: 'Catar',
      dial_code: '+974',
      emoji: '🇶🇦',
      id: 'QA',
    },
    {
      name: 'Reunião',
      dial_code: '+262',
      emoji: '🇷🇪',
      id: 'RE',
    },
    {
      name: 'Romênia',
      dial_code: '+40',
      emoji: '🇷🇴',
      id: 'RO',
    },
    {
      name: 'Rússia',
      dial_code: '+7',
      emoji: '🇷🇺',
      id: 'RU',
    },
    {
      name: 'Ruanda',
      dial_code: '+250',
      emoji: '🇷🇼',
      id: 'RW',
    },
    {
      name: 'Saint Barthélemy',
      dial_code: '+590',
      emoji: '🇧🇱',
      id: 'BL',
    },
    {
      name: 'Santa Helena, Ascensão e Tristão da Cunha',
      dial_code: '+290',
      emoji: '🇸🇭',
      id: 'SH',
    },
    {
      name: 'São Cristóvão e Névis',
      dial_code: '+1869',
      emoji: '🇰🇳',
      id: 'KN',
    },
    {
      name: 'Santa Lúcia',
      dial_code: '+1758',
      emoji: '🇱🇨',
      id: 'LC',
    },
    {
      name: 'São Martinho',
      dial_code: '+590',
      emoji: '🇲🇫',
      id: 'MF',
    },
    {
      name: 'São Pedro e Miquelon',
      dial_code: '+508',
      emoji: '🇵🇲',
      id: 'PM',
    },
    {
      name: 'São Vicente e Granadinas',
      dial_code: '+1784',
      emoji: '🇻🇨',
      id: 'VC',
    },
    {
      name: 'Samoa',
      dial_code: '+685',
      emoji: '🇼🇸',
      id: 'WS',
    },
    {
      name: 'San Marino',
      dial_code: '+378',
      emoji: '🇸🇲',
      id: 'SM',
    },
    {
      name: 'São Tomé e Príncipe',
      dial_code: '+239',
      emoji: '🇸🇹',
      id: 'ST',
    },
    {
      name: 'Arábia Saudita',
      dial_code: '+966',
      emoji: '🇸🇦',
      id: 'SA',
    },
    {
      name: 'Senegal',
      dial_code: '+221',
      emoji: '🇸🇳',
      id: 'SN',
    },
    {
      name: 'Sérvia',
      dial_code: '+381',
      emoji: '🇷🇸',
      id: 'RS',
    },
    {
      name: 'Seychelles',
      dial_code: '+248',
      emoji: '🇸🇨',
      id: 'SC',
    },
    {
      name: 'Serra Leoa',
      dial_code: '+232',
      emoji: '🇸🇱',
      id: 'SL',
    },
    {
      name: 'Singapura',
      dial_code: '+65',
      emoji: '🇸🇬',
      id: 'SG',
    },
    {
      name: 'Eslováquia',
      dial_code: '+421',
      emoji: '🇸🇰',
      id: 'SK',
    },
    {
      name: 'Eslovênia',
      dial_code: '+386',
      emoji: '🇸🇮',
      id: 'SI',
    },
    {
      name: 'Ilhas Salomão',
      dial_code: '+677',
      emoji: '🇸🇧',
      id: 'SB',
    },
    {
      name: 'Somália',
      dial_code: '+252',
      emoji: '🇸🇴',
      id: 'SO',
    },
    {
      name: 'África do Sul',
      dial_code: '+27',
      emoji: '🇿🇦',
      id: 'ZA',
    },
    {
      name: 'Sudão do Sul',
      dial_code: '+211',
      emoji: '🇸🇸',
      id: 'SS',
    },
    {
      name: 'Geórgia do Sul e Ilhas Sandwich do Sul',
      dial_code: '+500',
      emoji: '🇬🇸',
      id: 'GS',
    },
    {
      name: 'Espanha',
      dial_code: '+34',
      emoji: '🇪🇸',
      id: 'ES',
    },
    {
      name: 'Sri Lanka',
      dial_code: '+94',
      emoji: '🇱🇰',
      id: 'LK',
    },
    {
      name: 'Sudão',
      dial_code: '+249',
      emoji: '🇸🇩',
      id: 'SD',
    },
    {
      name: 'Suriname',
      dial_code: '+597',
      emoji: '🇸🇷',
      id: 'SR',
    },
    {
      name: 'Svalbard e Jan Mayen',
      dial_code: '+47',
      emoji: '🇸🇯',
      id: 'SJ',
    },
    {
      name: 'Suécia',
      dial_code: '+46',
      emoji: '🇸🇪',
      id: 'SE',
    },
    {
      name: 'Suíça',
      dial_code: '+41',
      emoji: '🇨🇭',
      id: 'CH',
    },
    {
      name: 'Síria',
      dial_code: '+963',
      emoji: '🇸🇾',
      id: 'SY',
    },
    {
      name: 'Taiwan',
      dial_code: '+886',
      emoji: '🇹🇼',
      id: 'TW',
    },
    {
      name: 'Tajiquistão',
      dial_code: '+992',
      emoji: '🇹🇯',
      id: 'TJ',
    },
    {
      name: 'Tanzânia',
      dial_code: '+255',
      emoji: '🇹🇿',
      id: 'TZ',
    },
    {
      name: 'Tailândia',
      dial_code: '+66',
      emoji: '🇹🇭',
      id: 'TH',
    },
    {
      name: 'Timor-Leste',
      dial_code: '+670',
      emoji: '🇹🇱',
      id: 'TL',
    },
    {
      name: 'Togo',
      dial_code: '+228',
      emoji: '🇹🇬',
      id: 'TG',
    },
    {
      name: 'Tokelau',
      dial_code: '+690',
      emoji: '🇹🇰',
      id: 'TK',
    },
    {
      name: 'Tonga',
      dial_code: '+676',
      emoji: '🇹🇴',
      id: 'TO',
    },
    {
      name: 'Trindade e Tobago',
      dial_code: '+1868',
      emoji: '🇹🇹',
      id: 'TT',
    },
    {
      name: 'Tunísia',
      dial_code: '+216',
      emoji: '🇹🇳',
      id: 'TN',
    },
    {
      name: 'Turquia',
      dial_code: '+90',
      emoji: '🇹🇷',
      id: 'TR',
    },
    {
      name: 'Turquemenistão',
      dial_code: '+993',
      emoji: '🇹🇲',
      id: 'TM',
    },
    {
      name: 'Ilhas Turcas e Caicos',
      dial_code: '+1649',
      emoji: '🇹🇨',
      id: 'TC',
    },
    {
      name: 'Tuvalu',
      dial_code: '+688',
      emoji: '🇹🇻',
      id: 'TV',
    },
    {
      name: 'Uganda',
      dial_code: '+256',
      emoji: '🇺🇬',
      id: 'UG',
    },
    {
      name: 'Ucrânia',
      dial_code: '+380',
      emoji: '🇺🇦',
      id: 'UA',
    },
    {
      name: 'Emirados Árabes Unidos',
      dial_code: '+971',
      emoji: '🇦🇪',
      id: 'AE',
    },
    {
      name: 'Reino Unido',
      dial_code: '+44',
      emoji: '🇬🇧',
      id: 'GB',
    },
    {
      name: 'Estados Unidos',
      dial_code: '+1',
      emoji: '🇺🇸',
      id: 'US',
    },
    {
      name: 'Uruguai',
      dial_code: '+598',
      emoji: '🇺🇾',
      id: 'UY',
    },
    {
      name: 'Uzbequistão',
      dial_code: '+998',
      emoji: '🇺🇿',
      id: 'UZ',
    },
    {
      name: 'Vanuatu',
      dial_code: '+678',
      emoji: '🇻🇺',
      id: 'VU',
    },
    {
      name: 'Cidade do Vaticano',
      dial_code: '+379',
      emoji: '🇻🇦',
      id: 'VA',
    },
    {
      name: 'Venezuela',
      dial_code: '+58',
      emoji: '🇻🇪',
      id: 'VE',
    },
    {
      name: 'Vietnã',
      dial_code: '+84',
      emoji: '🇻🇳',
      id: 'VN',
    },
    {
      name: 'Ilhas Virgens Britânicas',
      dial_code: '+1284',
      emoji: '🇻🇬',
      id: 'VG',
    },
    {
      name: 'Ilhas Virgens Americanas',
      dial_code: '+1340',
      emoji: '🇻🇮',
      id: 'VI',
    },
    {
      name: 'Wallis e Futuna',
      dial_code: '+681',
      emoji: '🇼🇫',
      id: 'WF',
    },
    {
      name: 'Iémen',
      dial_code: '+967',
      emoji: '🇾🇪',
      id: 'YE',
    },
    {
      name: 'Zâmbia',
      dial_code: '+260',
      emoji: '🇿🇲',
      id: 'ZM',
    },
    {
      name: 'Zimbábue',
      dial_code: '+263',
      emoji: '🇿🇼',
      id: 'ZW',
    },
  ];
  
  export default pt_BR;