var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[(_vm.show)?_c('div',{class:_vm.modalClassName,attrs:{"transition":"modal"}},[_c('div',{class:{
        'modal-container rtl:text-center shadow-md overflow-auto relative bg-white dark:bg-slate-800 skip-context-menu': true,
        'rounded-xl': !_vm.fullWidth,
        'items-center rounded-none flex h-full justify-center w-full':
          _vm.fullWidth,
        [_vm.size]: true,
      },on:{"mouse":function($event){$event.stopPropagation();}}},[_vm._t("default")],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }