import Vue from 'vue';
import BrandsAPI from '../../api/brands';

export const SET_BRAND_MEMBERS_UI_FLAG = 'SET_BRAND_MEMBERS_UI_FLAG';
export const ADD_AGENTS_TO_BRAND = 'ADD_AGENTS_TO_BRAND';

export const state = {
  records: {},
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getBrandMembers: $state => id => {
    return $state.records[id] || [];
  },
};

export const actions = {
  get: async ({ commit }, { brandId }) => {
    commit(SET_BRAND_MEMBERS_UI_FLAG, { isFetching: true });
    try {
      const { data } = await BrandsAPI.getAgents({ brandId });
      commit(ADD_AGENTS_TO_BRAND, { data, brandId });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_BRAND_MEMBERS_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, { agentsList, brandId }) => {
    commit(SET_BRAND_MEMBERS_UI_FLAG, { isCreating: true });
    try {
      const { data } = await BrandsAPI.addAgents({ agentsList, brandId });
      commit(ADD_AGENTS_TO_BRAND, { brandId, data });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_BRAND_MEMBERS_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { agentsList, brandId }) => {
    commit(SET_BRAND_MEMBERS_UI_FLAG, { isUpdating: true });
    try {
      const response = await BrandsAPI.updateAgents({
        agentsList,
        brandId,
      });
      commit(ADD_AGENTS_TO_BRAND, response);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_BRAND_MEMBERS_UI_FLAG, { isUpdating: false });
    }
  },
};

export const mutations = {
  [SET_BRAND_MEMBERS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [ADD_AGENTS_TO_BRAND]($state, { data, brandId }) {
    Vue.set($state.records, brandId, data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
