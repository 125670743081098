const pt_BR = {
    af: 'Africâner',
    af_NA: 'Africâner (Namíbia)',
    af_ZA: 'Africâner (África do Sul)',
    ak: 'Akan',
    ak_GH: 'Akan (Gana)',
    sq: 'Albanês',
    sq_AL: 'Albanês (Albânia)',
    sq_XK: 'Albanês (Kosovo)',
    sq_MK: 'Albanês (Macedônia)',
    am: 'Amárico',
    am_ET: 'Amárico (Etiópia)',
    ar: 'Árabe',
    ar_DZ: 'Árabe (Argélia)',
    ar_BH: 'Árabe (Bahrein)',
    ar_TD: 'Árabe (Chade)',
    ar_KM: 'Árabe (Comores)',
    ar_DJ: 'Árabe (Djibouti)',
    ar_EG: 'Árabe (Egito)',
    ar_ER: 'Árabe (Eritreia)',
    ar_IQ: 'Árabe (Iraque)',
    ar_IL: 'Árabe (Israel)',
    ar_JO: 'Árabe (Jordânia)',
    ar_KW: 'Árabe (Kuwait)',
    ar_LB: 'Árabe (Líbano)',
    ar_LY: 'Árabe (Líbia)',
    ar_MR: 'Árabe (Mauritânia)',
    ar_MA: 'Árabe (Marrocos)',
    ar_OM: 'Árabe (Omã)',
    ar_PS: 'Árabe (Territórios Palestinos)',
    ar_QA: 'Árabe (Catar)',
    ar_SA: 'Árabe (Arábia Saudita)',
    ar_SO: 'Árabe (Somália)',
    ar_SS: 'Árabe (Sudão do Sul)',
    ar_SD: 'Árabe (Sudão)',
    ar_SY: 'Árabe (Síria)',
    ar_TN: 'Árabe (Tunísia)',
    ar_AE: 'Árabe (Emirados Árabes Unidos)',
    ar_EH: 'Árabe (Saara Ocidental)',
    ar_YE: 'Árabe (Iêmen)',
    hy: 'Armênio',
    hy_AM: 'Armênio (Armênia)',
    as: 'Assamês',
    as_IN: 'Assamês (Índia)',
    az: 'Azerbaijano',
    az_AZ: 'Azerbaijano (Azerbaijão)',
    az_Cyrl_AZ: 'Azerbaijano (Cirílico, Azerbaijão)',
    az_Cyrl: 'Azerbaijano (Cirílico)',
    az_Latn_AZ: 'Azerbaijano (Latino, Azerbaijão)',
    az_Latn: 'Azerbaijano (Latino)',
    bm: 'Bambara',
    bm_Latn_ML: 'Bambara (Latino, Mali)',
    bm_Latn: 'Bambara (Latino)',
    eu: 'Basco',
    eu_ES: 'Basco (Espanha)',
    be: 'Bielorrusso',
    be_BY: 'Bielorrusso (Bielorrússia)',
    bn: 'Bengali',
    bn_BD: 'Bengali (Bangladesh)',
    bn_IN: 'Bengali (Índia)',
    bs: 'Bósnio',
    bs_BA: 'Bósnio (Bósnia e Herzegovina)',
    bs_Cyrl_BA: 'Bósnio (Cirílico, Bósnia e Herzegovina)',
    bs_Cyrl: 'Bósnio (Cirílico)',
    bs_Latn_BA: 'Bósnio (Latino, Bósnia e Herzegovina)',
    bs_Latn: 'Bósnio (Latino)',
    br: 'Bretão',
    br_FR: 'Bretão (França)',
    bg: 'Búlgaro',
    bg_BG: 'Búlgaro (Bulgária)',
    my: 'Birmanês',
    my_MM: 'Birmanês (Mianmar (Birmânia))',
    ca: 'Catalão',
    ca_AD: 'Catalão (Andorra)',
    ca_FR: 'Catalão (França)',
    ca_IT: 'Catalão (Itália)',
    ca_ES: 'Catalão (Espanha)',
    zh: 'Chinês',
    zh_CN: 'Chinês (China)',
    zh_HK: 'Chinês (Hong Kong, RAE da China)',
    zh_MO: 'Chinês (Macau, RAE da China)',
    zh_Hans_CN: 'Chinês (Simplificado, China)',
    zh_Hans_HK: 'Chinês (Simplificado, Hong Kong, RAE da China)',
    zh_Hans_MO: 'Chinês (Simplificado, Macau, RAE da China)',
    zh_Hans_SG: 'Chinês (Simplificado, Singapura)',
    zh_Hans: 'Chinês (Simplificado)',
    zh_SG: 'Chinês (Singapura)',
    zh_TW: 'Chinês (Taiwan)',
    zh_Hant_HK: 'Chinês (Tradicional, Hong Kong, RAE da China)',
    zh_Hant_MO: 'Chinês (Tradicional, Macau, RAE da China)',
    zh_Hant_TW: 'Chinês (Tradicional, Taiwan)',
    zh_Hant: 'Chinês (Tradicional)',
    kw: 'Córnico',
    kw_GB: 'Córnico (Reino Unido)',
    hr: 'Croata',
    hr_BA: 'Croata (Bósnia e Herzegovina)',
    hr_HR: 'Croata (Croácia)',
    cs: 'Tcheco',
    cs_CZ: 'Tcheco (República Tcheca)',
    da: 'Dinamarquês',
    da_DK: 'Dinamarquês (Dinamarca)',
    da_GL: 'Dinamarquês (Groenlândia)',
    nl: 'Holandês',
    nl_AW: 'Holandês (Aruba)',
    nl_BE: 'Holandês (Bélgica)',
    nl_BQ: 'Holandês (Países Baixos do Caribe)',
    nl_CW: 'Holandês (Curaçao)',
    nl_NL: 'Holandês (Países Baixos)',
    nl_SX: 'Holandês (Sint Maarten)',
    nl_SR: 'Holandês (Suriname)',
    dz: 'Dzongkha',
    dz_BT: 'Dzongkha (Butão)',
    en: 'Inglês',
    en_AS: 'Inglês (Samoa Americana)',
    en_AI: 'Inglês (Anguilla)',
    en_AG: 'Inglês (Antígua e Barbuda)',
    en_AU: 'Inglês (Austrália)',
    en_BS: 'Inglês (Bahamas)',
    en_BB: 'Inglês (Barbados)',
    en_BE: 'Inglês (Bélgica)',
    en_BZ: 'Inglês (Belize)',
    en_BM: 'Inglês (Bermudas)',
    en_BW: 'Inglês (Botsuana)',
    en_IO: 'Inglês (Território Britânico do Oceano Índico)',
    en_VG: 'Inglês (Ilhas Virgens Britânicas)',
    en_CM: 'Inglês (Camarões)',
    en_CA: 'Inglês (Canadá)',
    en_KY: 'Inglês (Ilhas Cayman)',
    en_CX: 'Inglês (Ilha Christmas)',
    en_CC: 'Inglês (Ilhas Cocos (Keeling))',
    en_CK: 'Inglês (Ilhas Cook)',
    en_DG: 'Inglês (Diego Garcia)',
    en_DM: 'Inglês (Dominica)',
    en_ER: 'Inglês (Eritreia)',
    en_FK: 'Inglês (Ilhas Malvinas)',
    en_FJ: 'Inglês (Fiji)',
    en_GM: 'Inglês (Gâmbia)',
    en_GH: 'Inglês (Gana)',
    en_GI: 'Inglês (Gibraltar)',
    en_GD: 'Inglês (Granada)',
    en_GU: 'Inglês (Guam)',
    en_GG: 'Inglês (Guernsey)',
    en_GY: 'Inglês (Guiana)',
    en_HK: 'Inglês (Hong Kong, RAE da China)',
    en_IN: 'Inglês (Índia)',
    en_IE: 'Inglês (Irlanda)',
    en_IM: 'Inglês (Ilha de Man)',
    en_JM: 'Inglês (Jamaica)',
    en_JE: 'Inglês (Jersey)',
    en_KE: 'Inglês (Quênia)',
    en_KI: 'Inglês (Kiribati)',
    en_LS: 'Inglês (Lesoto)',
    en_LR: 'Inglês (Libéria)',
    en_MO: 'Inglês (Macau, RAE da China)',
    en_MG: 'Inglês (Madagascar)',
    en_MW: 'Inglês (Malawi)',
    en_MY: 'Inglês (Malásia)',
    en_MT: 'Inglês (Malta)',
    en_MH: 'Inglês (Ilhas Marshall)',
    en_MU: 'Inglês (Maurício)',
    en_FM: 'Inglês (Micronésia)',
    en_MS: 'Inglês (Montserrat)',
    en_NA: 'Inglês (Namíbia)',
    en_NR: 'Inglês (Nauru)',
    en_NZ: 'Inglês (Nova Zelândia)',
    en_NG: 'Inglês (Nigéria)',
    en_NU: 'Inglês (Niue)',
    en_NF: 'Inglês (Ilha Norfolk)',
    en_MP: 'Inglês (Ilhas Marianas do Norte)',
    en_PK: 'Inglês (Paquistão)',
    en_PW: 'Inglês (Palau)',
    en_PG: 'Inglês (Papua Nova Guiné)',
    en_PH: 'Inglês (Filipinas)',
    en_PN: 'Inglês (Ilhas Pitcairn)',
    en_PR: 'Inglês (Porto Rico)',
    en_RW: 'Inglês (Ruanda)',
    en_WS: 'Inglês (Samoa)',
    en_SC: 'Inglês (Seychelles)',
    en_SL: 'Inglês (Serra Leoa)',
    en_SG: 'Inglês (Singapura)',
    en_SX: 'Inglês (Sint Maarten)',
    en_SB: 'Inglês (Ilhas Salomão)',
    en_ZA: 'Inglês (África do Sul)',
    en_SS: 'Inglês (Sudão do Sul)',
    en_SH: 'Inglês (Santa Helena)',
    en_KN: 'Inglês (São Cristóvão e Névis)',
    en_LC: 'Inglês (Santa Lúcia)',
    en_VC: 'Inglês (São Vicente e Granadinas)',
    en_SD: 'Inglês (Sudão)',
    en_SZ: 'Inglês (Suazilândia)',
    en_TZ: 'Inglês (Tanzânia)',
    en_TK: 'Inglês (Tokelau)',
    en_TO: 'Inglês (Tonga)',
    en_TT: 'Inglês (Trinidad e Tobago)',
    en_TC: 'Inglês (Ilhas Turcas e Caicos)',
    en_TV: 'Inglês (Tuvalu)',
    en_UM: 'Inglês (Ilhas Menores Distantes dos EUA)',
    en_VI: 'Inglês (Ilhas Virgens Americanas)',
    en_UG: 'Inglês (Uganda)',
    en_GB: 'Inglês (Reino Unido)',
    en_US: 'Inglês (Estados Unidos)',
    en_VU: 'Inglês (Vanuatu)',
    en_ZM: 'Inglês (Zâmbia)',
    en_ZW: 'Inglês (Zimbábue)',
    eo: 'Esperanto',
    et: 'Estoniano',
    et_EE: 'Estoniano (Estônia)',
    ee: 'Ewe',
    ee_GH: 'Ewe (Gana)',
    ee_TG: 'Ewe (Togo)',
    fo: 'Feroês',
    fo_FO: 'Feroês (Ilhas Faroé)',
    fi: 'Finlandês',
    fi_FI: 'Finlandês (Finlândia)',
    fr: 'Francês',
    fr_DZ: 'Francês (Argélia)',
    fr_BE: 'Francês (Bélgica)',
    fr_BJ: 'Francês (Benin)',
    fr_BF: 'Francês (Burkina Faso)',
    fr_BI: 'Francês (Burundi)',
    fr_CM: 'Francês (Camarões)',
    fr_CA: 'Francês (Canadá)',
    fr_CF: 'Francês (República Centro-Africana)',
    fr_TD: 'Francês (Chade)',
    fr_KM: 'Francês (Comores)',
    fr_CG: 'Francês (Congo - Brazzaville)',
    fr_CD: 'Francês (Congo - Kinshasa)',
    fr_CI: 'Francês (Costa do Marfim)',
    fr_DJ: 'Francês (Djibouti)',
    fr_GQ: 'Francês (Guiné Equatorial)',
    fr_FR: 'Francês (França)',
    fr_GF: 'Francês (Guiana Francesa)',
    fr_PF: 'Francês (Polinésia Francesa)',
    fr_GA: 'Francês (Gabão)',
    fr_GP: 'Francês (Guadalupe)',
    fr_GN: 'Francês (Guiné)',
    fr_HT: 'Francês (Haiti)',
    fr_LU: 'Francês (Luxemburgo)',
    fr_MG: 'Francês (Madagascar)',
    fr_ML: 'Francês (Mali)',
    fr_MQ: 'Francês (Martinica)',
    fr_MR: 'Francês (Mauritânia)',
    fr_MU: 'Francês (Maurício)',
    fr_YT: 'Francês (Mayotte)',
    fr_MC: 'Francês (Mônaco)',
    fr_MA: 'Francês (Marrocos)',
    fr_NC: 'Francês (Nova Caledônia)',
    fr_NE: 'Francês (Níger)',
    fr_RE: 'Francês (Reunião)',
    fr_RW: 'Francês (Ruanda)',
    fr_SN: 'Francês (Senegal)',
    fr_SC: 'Francês (Seicheles)',
    fr_BL: 'Francês (São Bartolomeu)',
    fr_MF: 'Francês (São Martinho)',
    fr_PM: 'Francês (São Pedro e Miquelão)',
    fr_CH: 'Francês (Suíça)',
    fr_SY: 'Francês (Síria)',
    fr_TG: 'Francês (Togo)',
    fr_TN: 'Francês (Tunísia)',
    fr_VU: 'Francês (Vanuatu)',
    fr_WF: 'Francês (Wallis e Futuna)',
    ff: 'Fula',
    ff_CM: 'Fula (Camarões)',
    ff_GN: 'Fula (Guiné)',
    ff_MR: 'Fula (Mauritânia)',
    ff_SN: 'Fula (Senegal)',
    gl: 'Galego',
    gl_ES: 'Galego (Espanha)',
    lg: 'Ganda',
    lg_UG: 'Ganda (Uganda)',
    ka: 'Georgiano',
    ka_GE: 'Georgiano (Geórgia)',
    de: 'Alemão',
    de_AT: 'Alemão (Áustria)',
    de_BE: 'Alemão (Bélgica)',
    de_DE: 'Alemão (Alemanha)',
    de_LI: 'Alemão (Liechtenstein)',
    de_LU: 'Alemão (Luxemburgo)',
    de_CH: 'Alemão (Suíça)',
    el: 'Grego',
    el_CY: 'Grego (Chipre)',
    el_GR: 'Grego (Grécia)',
    gu: 'Guzerate',
    gu_IN: 'Guzerate (Índia)',
    ha: 'Hauçá',
    ha_GH: 'Hauçá (Gana)',
    ha_Latn_GH: 'Hauçá (Latim, Gana)',
    ha_Latn_NE: 'Hauçá (Latim, Níger)',
    ha_Latn_NG: 'Hauçá (Latim, Nigéria)',
    ha_Latn: 'Hauçá (Latim)',
    ha_NE: 'Hauçá (Níger)',
    ha_NG: 'Hauçá (Nigéria)',
    he: 'Hebraico',
    he_IL: 'Hebraico (Israel)',
    hi: 'Hindi',
    hi_IN: 'Hindi (Índia)',
    hu: 'Húngaro',
    hu_HU: 'Húngaro (Hungria)',
    is: 'Islandês',
    is_IS: 'Islandês (Islândia)',
    ig: 'Igbo',
    ig_NG: 'Igbo (Nigéria)',
    id: 'Indonésio',
    id_ID: 'Indonésio (Indonésia)',
    ga: 'Irlandês',
    ga_IE: 'Irlandês (Irlanda)',
    it: 'Italiano',
    it_IT: 'Italiano (Itália)',
    it_SM: 'Italiano (San Marino)',
    it_CH: 'Italiano (Suíça)',
    ja: 'Japonês',
    ja_JP: 'Japonês (Japão)',
    kl: 'Kalaallisut',
    kl_GL: 'Kalaallisut (Groenlândia)',
    kn: 'Canará',
    kn_IN: 'Canará (Índia)',
    ks: 'Caxemira',
    ks_Arab_IN: 'Caxemira (Árabe, Índia)',
    ks_Arab: 'Caxemira (Árabe)',
    ks_IN: 'Caxemira (Índia)',
    kk: 'Cazaque',
    kk_Cyrl_KZ: 'Cazaque (Cirílico, Cazaquistão)',
    kk_Cyrl: 'Cazaque (Cirílico)',
    kk_KZ: 'Cazaque (Cazaquistão)',
    km: 'Khmer',
    km_KH: 'Khmer (Camboja)',
    ki: 'Quicuio',
    ki_KE: 'Quicuio (Quênia)',
    rw: 'Kinyarwanda',
    rw_RW: 'Kinyarwanda (Ruanda)',
    ko: 'Coreano',
    ko_KP: 'Coreano (Coreia do Norte)',
    ko_KR: 'Coreano (Coreia do Sul)',
    ky: 'Quirguiz',
    ky_Cyrl_KG: 'Quirguiz (Cirílico, Quirguistão)',
    ky_Cyrl: 'Quirguiz (Cirílico)',
    ky_KG: 'Quirguiz (Quirguistão)',
    lo: 'Lao',
    lo_LA: 'Lao (Laos)',
    lv: 'Letão',
    lv_LV: 'Letão (Letônia)',
    ln: 'Lingala',
    ln_AO: 'Lingala (Angola)',
    ln_CF: 'Lingala (República Centro-Africana)',
    ln_CG: 'Lingala (Congo - Brazzaville)',
    ln_CD: 'Lingala (Congo - Kinshasa)',
    lt: 'Lituano',
    lt_LT: 'Lituano (Lituânia)',
    lu: 'Luba-Katanga',
    lu_CD: 'Luba-Katanga (Congo - Kinshasa)',
    lb: 'Luxemburguês',
    lb_LU: 'Luxemburguês (Luxemburgo)',
    mk: 'Macedônio',
    mk_MK: 'Macedônio (Macedônia)',
    mg: 'Malgaxe',
    mg_MG: 'Malgaxe (Madagascar)',
    ms: 'Malaio',
    ms_BN: 'Malaio (Brunei)',
    ms_Latn_BN: 'Malaio (Latim, Brunei)',
    ms_Latn_MY: 'Malaio (Latim, Malásia)',
    ms_Latn_SG: 'Malaio (Latim, Singapura)',
    ms_Latn: 'Malaio (Latim)',
    ms_MY: 'Malaio (Malásia)',
    ms_SG: 'Malaio (Singapura)',
    ml: 'Malaiala',
    ml_IN: 'Malaiala (Índia)',
    mt: 'Maltês',
    mt_MT: 'Maltês (Malta)',
    gv: 'Manx',
    gv_IM: 'Manx (Ilha de Man)',
    mr: 'Marata',
    mr_IN: 'Marata (Índia)',
    mn: 'Mongol',
    mn_Cyrl_MN: 'Mongol (Cirílico, Mongólia)',
    mn_Cyrl: 'Mongol (Cirílico)',
    mn_MN: 'Mongol (Mongólia)',
    ne: 'Nepali',
    ne_IN: 'Nepali (Índia)',
    ne_NP: 'Nepali (Nepal)',
    nd: 'Ndebele do Norte',
    nd_ZW: 'Ndebele do Norte (Zimbábue)',
    se: 'Sami do Norte',
    se_FI: 'Sami do Norte (Finlândia)',
    se_NO: 'Sami do Norte (Noruega)',
    se_SE: 'Sami do Norte (Suécia)',
    no: 'Norueguês',
    no_NO: 'Norueguês (Noruega)',
    nb: 'Bokmål norueguês',
    nb_NO: 'Bokmål norueguês (Noruega)',
    nb_SJ: 'Bokmål norueguês (Svalbard e Jan Mayen)',
    nn: 'Nynorsk norueguês',
    nn_NO: 'Nynorsk norueguês (Noruega)',
    or: 'Oriá',
    or_IN: 'Oriá (Índia)',
    om: 'Oromo',
    om_ET: 'Oromo (Etiópia)',
    om_KE: 'Oromo (Quênia)',
    os: 'Ossético',
    os_GE: 'Ossético (Geórgia)',
    os_RU: 'Ossético (Rússia)',
    ps: 'Pastó',
    ps_AF: 'Pastó (Afeganistão)',
    fa: 'Persa',
    fa_AF: 'Persa (Afeganistão)',
    fa_IR: 'Persa (Irã)',
    pl: 'Polonês',
    pl_PL: 'Polonês (Polônia)',
    pt: 'Português',
    pt_AO: 'Português (Angola)',
    pt_BR: 'Português (Brasil)',
    pt_CV: 'Português (Cabo Verde)',
    pt_GW: 'Português (Guiné-Bissau)',
    pt_MO: 'Português (Macau RAE China)',
    pt_MZ: 'Português (Moçambique)',
    pt_PT: 'Português (Portugal)',
    pt_ST: 'Português (São Tomé e Príncipe)',
    pt_TL: 'Português (Timor-Leste)',
    pa: 'Punjabi',
    pa_Arab_PK: 'Punjabi (Árabe, Paquistão)',
    pa_Arab: 'Punjabi (Árabe)',
    pa_Guru_IN: 'Punjabi (Gurmukhi, Índia)',
    pa_Guru: 'Punjabi (Gurmukhi)',
    pa_IN: 'Punjabi (Índia)',
    pa_PK: 'Punjabi (Paquistão)',
    qu: 'Quíchua',
    qu_BO: 'Quíchua (Bolívia)',
    qu_EC: 'Quíchua (Equador)',
    qu_PE: 'Quíchua (Peru)',
    ro: 'Romeno',
    ro_MD: 'Romeno (Moldávia)',
    ro_RO: 'Romeno (Romênia)',
    rm: 'Romanche',
    rm_CH: 'Romanche (Suíça)',
    rn: 'Kirundi',
    rn_BI: 'Kirundi (Burundi)',
    ru: 'Russo',
    ru_BY: 'Russo (Bielorrússia)',
    ru_KZ: 'Russo (Cazaquistão)',
    ru_KG: 'Russo (Quirguistão)',
    ru_MD: 'Russo (Moldávia)',
    ru_RU: 'Russo (Rússia)',
    ru_UA: 'Russo (Ucrânia)',
    sg: 'Sango',
    sg_CF: 'Sango (República Centro-Africana)',
    gd: 'Gaélico escocês',
    gd_GB: 'Gaélico escocês (Reino Unido)',
    sr: 'Sérvio',
    sr_BA: 'Sérvio (Bósnia e Herzegovina)',
    sr_Cyrl_BA: 'Sérvio (Cirílico, Bósnia e Herzegovina)',
    sr_Cyrl_XK: 'Sérvio (Cirílico, Kosovo)',
    sr_Cyrl_ME: 'Sérvio (Cirílico, Montenegro)',
    sr_Cyrl_RS: 'Sérvio (Cirílico, Sérvia)',
    sr_Cyrl: 'Sérvio (Cirílico)',
    sr_XK: 'Sérvio (Kosovo)',
    sr_Latn_BA: 'Sérvio (Latim, Bósnia e Herzegovina)',
    sr_Latn_XK: 'Sérvio (Latim, Kosovo)',
    sr_Latn_ME: 'Sérvio (Latim, Montenegro)',
    sr_Latn_RS: 'Sérvio (Latim, Sérvia)',
    sr_Latn: 'Sérvio (Latim)',
    sr_ME: 'Sérvio (Montenegro)',
    sr_RS: 'Sérvio (Sérvia)',
    sh: 'Servo-croata',
    sh_BA: 'Servo-croata (Bósnia e Herzegovina)',
    sn: 'Shona',
    sn_ZW: 'Shona (Zimbábue)',
    ii: 'Sichuan Yi',
    ii_CN: 'Sichuan Yi (China)',
    si: 'Cingalês',
    si_LK: 'Cingalês (Sri Lanka)',
    sk: 'Eslovaco',
    sk_SK: 'Eslovaco (Eslováquia)',
    sl: 'Esloveno',
    sl_SI: 'Esloveno (Eslovênia)',
    so: 'Somali',
    so_DJ: 'Somali (Djibuti)',
    so_ET: 'Somali (Etiópia)',
    so_KE: 'Somali (Quênia)',
    so_SO: 'Somali (Somália)',
    es: 'Espanhol',
    es_AR: 'Espanhol (Argentina)',
    es_BO: 'Espanhol (Bolívia)',
    es_IC: 'Espanhol (Ilhas Canárias)',
    es_EA: 'Espanhol (Ceuta e Melilha)',
    es_CL: 'Espanhol (Chile)',
    es_CO: 'Espanhol (Colômbia)',
    es_CR: 'Espanhol (Costa Rica)',
    es_CU: 'Espanhol (Cuba)',
    es_DO: 'Espanhol (República Dominicana)',
    es_EC: 'Espanhol (Equador)',
    es_SV: 'Espanhol (El Salvador)',
    es_GQ: 'Espanhol (Guiné Equatorial)',
    es_GT: 'Espanhol (Guatemala)',
    es_HN: 'Espanhol (Honduras)',
    es_MX: 'Espanhol (México)',
    es_NI: 'Espanhol (Nicarágua)',
    es_PA: 'Espanhol (Panamá)',
    es_PY: 'Espanhol (Paraguai)',
    es_PE: 'Espanhol (Peru)',
    es_PH: 'Espanhol (Filipinas)',
    es_PR: 'Espanhol (Porto Rico)',
    es_ES: 'Espanhol (Espanha)',
    es_US: 'Espanhol (Estados Unidos)',
    es_UY: 'Espanhol (Uruguai)',
    es_VE: 'Espanhol (Venezuela)',
    sw: 'Suaíli',
    sw_KE: 'Suaíli (Quênia)',
    sw_TZ: 'Suaíli (Tanzânia)',
    sw_UG: 'Suaíli (Uganda)',
    sv: 'Sueco',
    sv_AX: 'Sueco (Ilhas Åland)',
    sv_FI: 'Sueco (Finlândia)',
    sv_SE: 'Sueco (Suécia)',
    tl: 'Tagalo',
    tl_PH: 'Tagalo (Filipinas)',
    ta: 'Tâmil',
    ta_IN: 'Tâmil (Índia)',
    ta_MY: 'Tâmil (Malásia)',
    ta_SG: 'Tâmil (Singapura)',
    ta_LK: 'Tâmil (Sri Lanka)',
    te: 'Telugo',
    te_IN: 'Telugo (Índia)',
    th: 'Tailandês',
    th_TH: 'Tailandês (Tailândia)',
    bo: 'Tibetano',
    bo_CN: 'Tibetano (China)',
    bo_IN: 'Tibetano (Índia)',
    ti: 'Tigrínia',
    ti_ER: 'Tigrínia (Eritreia)',
    ti_ET: 'Tigrínia (Etiópia)',
    to: 'Tonganês',
    to_TO: 'Tonganês (Tonga)',
    tr: 'Turco',
    tr_CY: 'Turco (Chipre)',
    tr_TR: 'Turco (Turquia)',
    uk: 'Ucraniano',
    uk_UA: 'Ucraniano (Ucrânia)',
    ur: 'Urdu',
    ur_IN: 'Urdu (Índia)',
    ur_PK: 'Urdu (Paquistão)',
    ug: 'Uigur',
    ug_Arab_CN: 'Uigur (Árabe, China)',
    ug_Arab: 'Uigur (Árabe)',
    ug_CN: 'Uigur (China)',
    uz: 'Usbeque',
    uz_AF: 'Usbeque (Afeganistão)',
    uz_Arab_AF: 'Usbeque (Árabe, Afeganistão)',
    uz_Arab: 'Usbeque (Árabe)',
    uz_Cyrl_UZ: 'Usbeque (Cirílico, Uzbequistão)',
    uz_Cyrl: 'Usbeque (Cirílico)',
    uz_Latn_UZ: 'Usbeque (Latim, Uzbequistão)',
    uz_Latn: 'Usbeque (Latim)',
    uz_UZ: 'Usbeque (Uzbequistão)',
    vi: 'Vietnamita',
    vi_VN: 'Vietnamita (Vietnã)',
    cy: 'Galês',
    cy_GB: 'Galês (Reino Unido)',
    fy: 'Frísio ocidental',
    fy_NL: 'Frísio ocidental (Países Baixos)',
    yi: 'Iídiche',
    yo: 'Iorubá',
    yo_BJ: 'Iorubá (Benim)',
    yo_NG: 'Iorubá (Nigéria)',
    zu: 'Zulu',
    zu_ZA: 'Zulu (África do Sul)',
  }
  
  export default pt_BR;