import en from './countries/en';
import pt_BR from './countries/pt_BR.js';

const countriesI18n = (locale) => {
  const countries = {
    en,
    pt_BR,
  }

  return countries[locale] || en;
}

export default countriesI18n;