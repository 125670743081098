import en from './locales/en';
import pt_BR from './locales/pt_BR';

const localesI18n = (locale) => {
  const locales = {
    en,
    pt_BR,
  }

  return locales[locale] || en;
}

export default localesI18n;