/* global axios */
// import ApiClient from './ApiClient';
import CacheEnabledApiClient from './CacheEnabledApiClient';

export class BrandsAPI extends CacheEnabledApiClient {
  constructor() {
    super('brands', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'brand';
  }

  // eslint-disable-next-line class-methods-use-this
  extractDataFromResponse(response) {
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  marshallData(dataToParse) {
    return { data: dataToParse };
  }

  getAgents({ brandId }) {
    return axios.get(`${this.url}/${brandId}/brand_members`);
  }

  addAgents({ brandId, agentsList }) {
    return axios.post(`${this.url}/${brandId}/brand_members`, {
      user_ids: agentsList,
    });
  }

  updateAgents({ brandId, agentsList }) {
    return axios.patch(`${this.url}/${brandId}/brand_members`, {
      user_ids: agentsList,
    });
  }
}

export default new BrandsAPI();
