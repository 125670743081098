export const getters = {
  getBrands($state) {
    return Object.values($state.records).sort((a, b) => a.id - b.id);
  },
  getMyBrands($state, $getters) {
    return $getters.getBrands.filter(brand => {
      const { is_member: isMember } = brand;
      return isMember;
    });
  },
  getMyBrandsNames($state, $getters) {
    return $getters.getMyBrands.map(brand => brand.name.toLowerCase()) || [];
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getBrand: $state => id => {
    const brand = $state.records[id];
    return brand || {};
  },
};
