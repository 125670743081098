import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import AgentAPI from '../../api/agents';
import TeamsAPI from '../../api/teams';
import BrandsAPI from '../../api/brands';

export const state = {
  records: [],
  teamMembers: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getAgents($state) {
    return $state.records;
  },
  getBrandMembers($state) {
    return $state.records;
  },
  getVerifiedAgents($state) {
    return $state.records.filter(record => record.confirmed);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getAgentStatus($state) {
    let status = {
      online: $state.records.filter(
        agent => agent.availability_status === 'online'
      ).length,
      busy: $state.records.filter(agent => agent.availability_status === 'busy')
        .length,
      offline: $state.records.filter(
        agent => agent.availability_status === 'offline'
      ).length,
    };
    return status;
  },
  getMyAgents($state) {
    const brandMemberIds = $state.brandMembers.map(member => member.id);
    return $state.records.filter(agent => brandMemberIds.includes(agent.id));
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      const response = await AgentAPI.get();
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
      commit(types.default.SET_AGENTS, response.data);
    } catch (error) {
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },
  getBrandMembers: async ({ commit }, { brandIds }) => {
    try {
      // Create an array of promises for each brandId's team members
      const membersPromises = brandIds.map(brandId =>
        BrandsAPI.getAgents({ brandId }).then(response => response.data)
      );

      // Wait for all promises to resolve
      const membersArrays = await Promise.all(membersPromises);

      // Flatten the array of arrays into a single array of members
      const members = [].concat(...membersArrays);

      // Commit the retrieved team members to the store
      commit(types.default.SET_BRAND_MEMBERS, members);
    } catch (error) {
      throw new Error(error);
    }
  },
  getTeamMembers: async ({ commit }, { teamIds }) => {
    try {
      // Create an array of promises for each teamId's team members
      const membersPromises = teamIds.map(teamId =>
        TeamsAPI.getAgents({ teamId }).then(response => response.data)
      );

      // Wait for all promises to resolve
      const membersArrays = await Promise.all(membersPromises);

      // Flatten the array of arrays into a single array of members
      const members = [].concat(...membersArrays);

      // Commit the retrieved team members to the store
      commit(types.default.SET_TEAM_MEMBERS, members);
    } catch (error) {
      throw new Error(error);
    }
  },
  create: async ({ commit }, agentInfo) => {
    commit(types.default.SET_AGENT_CREATING_STATUS, true);
    try {
      const response = await AgentAPI.create(agentInfo);
      commit(types.default.ADD_AGENT, response.data);
      commit(types.default.SET_AGENT_CREATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_CREATING_STATUS, false);
      throw error;
    }
  },
  update: async ({ commit }, { id, ...agentParams }) => {
    commit(types.default.SET_AGENT_UPDATING_STATUS, true);
    try {
      const response = await AgentAPI.update(id, agentParams);
      commit(types.default.EDIT_AGENT, response.data);
      commit(types.default.SET_AGENT_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_UPDATING_STATUS, false);
      throw new Error(error);
    }
  },
  updateSingleAgentPresence: ({ commit }, { id, availabilityStatus }) => {
    commit(types.default.UPDATE_SINGLE_AGENT_PRESENCE, {
      id,
      availabilityStatus,
    });
  },
  updatePresence: async ({ commit }, data) => {
    commit(types.default.UPDATE_AGENTS_PRESENCE, data);
  },
  delete: async ({ commit }, agentId) => {
    commit(types.default.SET_AGENT_DELETING_STATUS, true);
    try {
      await AgentAPI.delete(agentId);
      commit(types.default.DELETE_AGENT, agentId);
      commit(types.default.SET_AGENT_DELETING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_DELETING_STATUS, false);
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_AGENT_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_AGENT_CREATING_STATUS]($state, status) {
    $state.uiFlags.isCreating = status;
  },
  [types.default.SET_AGENT_UPDATING_STATUS]($state, status) {
    $state.uiFlags.isUpdating = status;
  },
  [types.default.SET_AGENT_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },
  [types.default.SET_BRAND_MEMBERS]($state, brandMembers) {
    $state.brandMembers = brandMembers;
  },
  [types.default.SET_TEAM_MEMBERS]($state, teamMembers) {
    $state.teamMembers = teamMembers;
  },
  [types.default.SET_AGENTS]: MutationHelpers.set,
  [types.default.ADD_AGENT]: MutationHelpers.create,
  [types.default.EDIT_AGENT]: MutationHelpers.update,
  [types.default.DELETE_AGENT]: MutationHelpers.destroy,
  [types.default.UPDATE_AGENTS_PRESENCE]: MutationHelpers.updatePresence,
  [types.default.UPDATE_SINGLE_AGENT_PRESENCE]: (
    $state,
    { id, availabilityStatus }
  ) =>
    MutationHelpers.updateSingleRecordPresence($state.records, {
      id,
      availabilityStatus,
    }),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
