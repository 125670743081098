import Vue from 'vue';
import {
  SET_BRAND_UI_FLAG,
  CLEAR_BRANDS,
  SET_BRANDS,
  SET_BRAND_ITEM,
  EDIT_BRAND,
  DELETE_BRAND,
} from './types';

export const mutations = {
  [SET_BRAND_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [CLEAR_BRANDS]: $state => {
    Vue.set($state, 'records', {});
  },

  [SET_BRANDS]: ($state, data) => {
    data.forEach(brand => {
      Vue.set($state.records, brand.id, {
        ...($state.records[brand.id] || {}),
        ...brand,
      });
      return brand.id
    });
  },

  [SET_BRAND_ITEM]: ($state, data) => {
    Vue.set($state.records, data.id, {
      ...($state.records[data.id] || {}),
      ...data,
    });
  },

  [EDIT_BRAND]: ($state, data) => {
    Vue.set($state.records, data.id, data);
  },

  [DELETE_BRAND]: ($state, brandId) => {
    const { [brandId]: toDelete, ...records } = $state.records;
    Vue.set($state, 'records', records);
  },
};
