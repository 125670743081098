/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../../helper/URLHelper';

const CreateStepWrap = () => import('./Create/Index.vue');
const EditStepWrap = () => import('./Edit/Index.vue');
const CreateBrand = () => import('./Create/CreateBrand.vue');
const EditBrand = () => import('./Edit/EditBrand.vue');
const AddAgents = () => import('./Create/AddAgents.vue');
const EditAgents = () => import('./Edit/EditAgents.vue');
const FinishSetup = () => import('./FinishSetup.vue');
const SettingsContent = () => import('../Wrapper.vue');
const BrandsHome = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/brands'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_brands_list';
        return {
          headerTitle: 'BRANDS_SETTINGS.HEADER',
          headerButtonText: 'BRANDS_SETTINGS.NEW_BRAND',
          icon: 'people-team',
          newButtonRoutes: ['settings_brands_new'],
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_brands',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_brands_list',
          component: BrandsHome,
          roles: ['administrator'],
        },
        {
          path: 'new',
          component: CreateStepWrap,
          children: [
            {
              path: '',
              name: 'settings_brands_new',
              component: CreateBrand,
              roles: ['administrator'],
            },
            {
              path: ':brandId/finish',
              name: 'settings_brands_finish',
              component: FinishSetup,
              roles: ['administrator'],
            },
            {
              path: ':brandId/agents',
              name: 'settings_brands_add_agents',
              roles: ['administrator'],
              component: AddAgents,
            },
          ],
        },
        {
          path: ':brandId/edit',
          component: EditStepWrap,
          children: [
            {
              path: '',
              name: 'settings_brands_edit',
              component: EditBrand,
              roles: ['administrator'],
            },
            {
              path: 'agents',
              name: 'settings_brands_edit_members',
              component: EditAgents,
              roles: ['administrator'],
            },
            {
              path: 'finish',
              name: 'settings_brands_edit_finish',
              roles: ['administrator'],
              component: FinishSetup,
            },
          ],
        },
      ],
    },
  ],
};
