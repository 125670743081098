import {
  SET_BRAND_UI_FLAG,
  CLEAR_BRANDS,
  SET_BRANDS,
  SET_BRAND_ITEM,
  EDIT_BRAND,
  DELETE_BRAND,
} from './types';
import BrandsAPI from '../../../api/brands';

export const actions = {
  create: async ({ commit }, brandInfo) => {
    commit(SET_BRAND_UI_FLAG, { isCreating: true });
    try {
      const response = await BrandsAPI.create(brandInfo);
      const brand = response.data;
      commit(SET_BRAND_ITEM, brand);
      return brand;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_BRAND_UI_FLAG, { isCreating: false });
    }
  },
  revalidate: async ({ commit }, { newKey }) => {
    try {
      const isExistingKeyValid = await BrandsAPI.validateCacheKey(newKey);
      if (!isExistingKeyValid) {
        const response = await BrandsAPI.refetchAndCommit(newKey);
        commit(SET_BRANDS, response.data);
      }
    } catch (error) {
      // Ignore error
    }
  },
  get: async ({ commit }) => {
    commit(SET_BRAND_UI_FLAG, { isFetching: true });
    try {
      const response = await BrandsAPI.get();
      commit(CLEAR_BRANDS);
      commit(SET_BRANDS, response.data);
    } catch (error) {
      console.log("error", error)
      throw new Error(error);
    } finally {
      commit(SET_BRAND_UI_FLAG, { isFetching: false });
    }
  },

  show: async ({ commit }, { id }) => {
    commit(SET_BRAND_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await BrandsAPI.show(id);
      commit(SET_BRAND_ITEM, response.data.payload);
      commit(SET_BRAND_UI_FLAG, {
        isFetchingItem: false,
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_BRAND_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },

  update: async ({ commit }, { id, ...updateObj }) => {
    commit(SET_BRAND_UI_FLAG, { isUpdating: true });
    try {
      const response = await BrandsAPI.update(id, updateObj);
      commit(EDIT_BRAND, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_BRAND_UI_FLAG, { isUpdating: false });
    }
  },

  delete: async ({ commit }, brandId) => {
    commit(SET_BRAND_UI_FLAG, { isDeleting: true });
    try {
      await BrandsAPI.delete(brandId);
      commit(DELETE_BRAND, brandId);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_BRAND_UI_FLAG, { isDeleting: false });
    }
  },
};
