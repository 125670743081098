<template>
  <transition name="modal-fade">
    <div
      v-if="show"
      :class="modalClassName"
      transition="modal"
    >
      <div
        :class="{
          'modal-container rtl:text-center shadow-md overflow-auto relative bg-white dark:bg-slate-800 skip-context-menu': true,
          'rounded-xl': !fullWidth,
          'items-center rounded-none flex h-full justify-center w-full':
            fullWidth,
          [size]: true,
        }"
        @mouse.stop
      >
        <slot/>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: Boolean,
    fullWidth: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: 'centered',
    },
    size: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mousedDownOnBackdrop: false,
    };
  },
  computed: {
    modalClassName() {
      const modalClassNameMap = {
        centered: '',
        'right-aligned': 'right-aligned',
      };

      return `modal-take-over-mask skip-context-menu ${
        modalClassNameMap[this.modalType] || ''
      }`;
    },
  },
};
</script>

<style lang="scss">
.modal-take-over-mask {
  @apply fixed bottom-2 left-16 right-0 flex flex-col items-start z-[10] h-auto w-auto max-w-fit overflow-auto;
  .modal-container {
    &.medium {
      @apply max-w-[80%] w-[56.25rem];
    }
    // .content-box {
    //   @apply h-auto p-0;
    // }
    .content {
      @apply p-8;
    }
    form,
    .modal-content {
      @apply pt-4 pb-8 px-8 self-center;
      a {
        @apply p-4;
      }
    }
  }
}
.modal-big {
  @apply w-full;
}
.modal-mask.right-aligned {
  @apply justify-end;
  .modal-container {
    @apply rounded-none h-full w-[30rem];
  }
}
.modal-enter,
.modal-leave {
  @apply opacity-0;
}
.modal-enter .modal-container,
.modal-leave .modal-container {
  transform: scale(1.1);
}
</style>