<!-- eslint-disable vue/no-mutating-props -->
<template>
  <woot-take-over-modal
    :show.sync="show"
    :on-close="onClose"
    :show-close-button="false"
    :close-on-backdrop-click="false"
    :modal-type="'take-over-align'"
  >
    <div class="flex p-3 flex-col h-auto overflow-auto justify-center">
      <woot-modal-header
        :header-class="'flex flex-col items-center pb-0'"
        :header-title="$t('CONVERSATION.TAKE_OVER.TITLE')"
        :header-content="
          $t('CONVERSATION.TAKE_OVER.DESCRIPTION_USERS_QUEUE', {
            queueLength: takeOverConversationsCount,
          })
        "
      />
      <div class="flex flex-col row pt-8 justify-center">
        <div class="flex row justify-center">
          <woot-button
            class="w-auto text-center"
            :size="'small'"
            :is-disabled="blocked"
            :color-scheme="colorScheme"
            @click="takeDown"
          >
            {{ $t('CONVERSATION.TAKE_OVER.CONFIRM') }}
          </woot-button>
        </div>
        <div v-show="blocked" class="row text-center pt-6">
          <p class="leading-6 text-slate-800 dark:text-slate-50">
            {{ $t('CONVERSATION.TAKE_OVER.BLOCKED_DESCRIPTION')
            }}<span class="font-bold">{{ formattedBlockedTime }}</span>
          </p>
        </div>
      </div>
    </div>
  </woot-take-over-modal>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    blockedTime: {
      type: Number,
      default: 0,
    },
    currentUserId: {
      type: Number,
      default: null,
    },
    conversationId: {
      type: Number,
      default: null,
    },
    takeOverConversationsCount: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    blocked() {
      return this.blockedTime > 0;
    },
    colorScheme() {
      return this.blocked ? 'alert' : 'primary';
    },
    formattedBlockedTime() {
      const hours = Math.floor(this.blockedTime / (1000 * 60 * 60));
      const minutes = Math.floor(
        (this.blockedTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      return `${hours}:${minutes.toString().padStart(2, '0')}`;
    },
  },
  methods: {
    takeDown() {
      if (this.blocked) return;

      if (this.conversationId && this.currentUserId) {
        this.$store.dispatch('assignAgent', {
          conversationId: this.conversationId,
          agentId: this.currentUserId,
          takeOverConversation: true,
        }).then(() => {
          this.$store.dispatch('setTakeOverConversationsIds');
          this.showAlert(this.$t('CONVERSATION.CHANGE_AGENT'));
        }).catch(() => {
          if (error.response?.status === 422) {
            this.showAlert(this.$t('CONVERSATION.TAKE_OVER.UNPROCESSED'));
          } else {
            this.showAlert(this.$t('CONVERSATION.CHANGE_AGENT_FAILED'));
          }
        });
      }
    },
    isBlocked() {
      return this.blocked;
    },
    onClose() {
      this.$emit('cancel');
    },
  },
};
</script>
